// @flow

import React from 'react';
import classnames from 'classnames';

import './_hero-overlay.scss';

type Props = {
  bg?: React.Node,
  darkBg?: boolean,
  h1?: string,
  intro?: string,
  image: React.Node,
  className: string  
};

export default (props: Props) => {
  let styleProp;
  if (props.bg && props.bg !== 'brand--primary') {
    styleProp = { backgroundImage: `url(${props.bg}` };
  }
  return (
    <div
      className={classnames(
        'hero-overlay',
        {
          'hero-overlay--dark-bg': props.darkBg,
          'hero-overlay--bg--brand--primary': props.bg === 'brand--primary',
          'hero-overlay--narrow-text': props.narrowText
        },
        'd-flex',
        'align-items-end',
        'align-items-md-start',
        props.className
      )}
      style={styleProp}
    >
      <div className="container d-flex flex-column-reverse flex-md-row">
        <div className="hero-overlay-content-container">
          <div className="container p-0 hero-overlay-content">
            {props.h1 && <h1>{props.h1}</h1>}
            {props.h2 && <h4 dangerouslySetInnerHTML={{ __html: props.h2 }} />}
            {props.intro && (
              <p className="hero-overlay__intro">
                <strong dangerouslySetInnerHTML={{ __html: props.intro }} />
              </p>
            )}
            {props.children}
          </div>
        </div>
        {props.image}
      </div>
    </div>
  );
};
