import styled from 'styled-components';

import { Box } from '@latitude/box';

const StyledRatesBox = styled(Box)`
  ul {
    list-style: none;
    padding-left: 20px;
  }
  li {
    position: relative;
    &:before {
      content: '•';
      color: #63b8ff;
      position: absolute;
      left: -22px;
      font-size: 34px;
    }
  }
`;

export default StyledRatesBox;
