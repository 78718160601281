// Wrapper for react-slick, see docs at https://react-slick.neostack.com/docs/api

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';
import classnames from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './_slider.scss';

const defaultResponsiveSettings = [
  {
    breakpoint: 10000,
    settings: {
      // slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1024,
    settings: {
      // slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 992,
    settings: {
      // slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 768,
    settings: {
      // slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 450,
    settings: {
      // slidesToShow: 1,
      slidesToScroll: 1
    }
  }
];

class Slider extends Component {
  componentDidMount() {
    // this.slider.slickGoTo(1);
  }

  render() {
    const { className, equalHeightCards, guttersBetweenSlides, ...otherProps } = this.props;

    const responsiveSettings = this.props.responsiveSettings
      ? this.props.responsiveSettings
      : defaultResponsiveSettings;

    return (
      <div className="row">
        <div className="col-12">
          {this.props.title && 
          <div className="row">
            <div className="offset-2 col-8 text-center">
              <h2 className="mt-3 mb-5 ">{this.props.title}</h2>
            </div>
          </div>
          }
          <SlickSlider
            className={classnames(
              'lfs-slider',
              this.props.dots && 'lfs-slider--has-dots',
              this.props.greyDots && 'lfs-slider--grey-dots',
              equalHeightCards && 'lfs-slider--equal-height-cards',
              guttersBetweenSlides && 'lfs-slider--gutters-bw-slides',
              className
            )}
            responsive={responsiveSettings}
            ref={slider => (this.slider = slider)}
            {...otherProps}
          >
            {this.props.children}
          </SlickSlider>
        </div>
      </div>
    );
  }
}

// See docs at https://react-slick.neostack.com/docs/api for full propTypes
// to pass down to react-slick

Slider.propTypes = {
  gallery: PropTypes.bool,
  equalHeightFrom: PropTypes.oneOf(['md', 'lg']), // Enforces all slides to match the height of the largest one from a given screensize upwards
  guttersBetweenSlides: PropTypes.bool // True to use bootstrap grid gutter between every slide
};

Slider.defaultProps = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  gallery: false
};

export default Slider;
