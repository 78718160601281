// @flow

import classnames from 'classnames';
import * as React from 'react';

import './_card.scss';

type Props = {
  className?: string,
  image?: React.Node,
  imageBackgroundColor?: string,
  header?: string,
  headerTag?: string,
  title?: React.Node,
  content?: React.Node,
  footer?: string | React.node,
  partner?: boolean
};

const defaultProps = {
  headerTag: 'h4'
};

const Card = (props: Props) => (
  <div className={classnames(
    'card',
    'd-flex',
    'flex-column',
    props.logo && 'card--logo',
    props.className
  )}
  >
    { props.header &&
      <div className="card__header">
        <props.headerTag className="text--invert text-center m-0">{props.header}</props.headerTag>
      </div>
    }
    { props.image &&
      <div
        className="mb-2 card__img text-center"
        style={{ backgroundColor: props.imageBackgroundColor }}
      >
        {props.image}
      </div>
    }
    { props.title && <div className="mb-2">{props.title}</div> }
    { props.content }
    { props.children }
    { props.footer &&
      <div className="card__footer bg-lighter">
        { props.footer }
      </div>
    }
  </div>
);

Card.defaultProps = defaultProps;

export default Card;
