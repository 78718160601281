import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import { Box } from '@latitude/box';
import { ImportantInformation } from '@latitude/important-information';
import { AccordionSidebar } from '@latitude/accordion';
import { ALIGN, COLOR } from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';
import RatesAndFees from '@latitude/rates-and-fees';
import { StickyNavigation } from '@latitude/sticky-navigation';
import HeroWithOverlay from '../../components/HeroWithOverlay/HeroWithOverlay';
import Layout from '../../components/layout';
import Image from '../../components/Image/Image';
import PageData from '../../data/pages/gem-essential.json';
import withStickyState from '../../hoc/withStickyState';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import Slide from '../../components/Slide/Slide';
import Slider from '../../components/Slider/Slider';
import Card from '../../components/Card/Card';
import './gem-visa-card.scss';
import DigitalWallets from '../../components/DigitalWallets/DigitalWallets';
import StyledRatesBox from './_styled-rates-box';
import CalloutHelp from './_callout-help';
import faqsCredit from './_faqs';
import { PageContext } from '../../context/PageContext';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { getAccordionSidebarData } from '@/utils/helpers';

const GemEssentialVisaPage = props => {
  /**
   * TODO
   * 1. Remove stroke override once page is rebranded
   * 2. Use latest version of @latitude/svg-inline and fix corresponding assets where applicable
   *
   * INFO
   * - Fixes stroke hex being added to the svg 'g' element
   */
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  const useStrokeOverride = `g[stroke] { stroke: #0046aa; }`;

  return (
    <Layout>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer gem-visa-page">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/credit-cards/gem-essential/"
          />
          <title>Gem Essential Credit Card | Gem by Latitude</title>
          <meta
            name="description"
            content="Gem Essential is currently not open to new applicants. Gem Visa might be the right credit card for you. T&amp;Cs &amp; fees apply."
          />
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <HeroWithOverlay
            h1={PageData.content.heroTitle}
            h2=""
            intro={PageData.content.heroIntro}
            bg={require('../../images/gem-visa-home-hero.png')}
            darkBg
            narrowText
            className="hero-overlay--shopping w-100"
            image={
              <Image
                title="Gem Visa"
                alt="Gem Visa"
                className="drop-shadow-image border-radius-card vertical--cc-image"
                src={require('../../images/credit-cards/gem-essential-visa-card.png')}
              />
            }
          />
        )}

        <StickyNavigation
          items={PageData.nav}
          isSticky={props.isNavSticky}
          onStickyNavStateChange={props.handleStickyNavStateChange}
          offsetElem="[data-sticky-navigation-offset]"
          {...state?.inPageNavData?.[0]}
        />

        <Section id="gem-features" className="bg-lightest pb-4">
          <Slider
            title={
              <div>
                <div>{PageData.content.features.titleText1}</div>
                <div>{PageData.content.features.titleText2}</div>
              </div>
            }
            className="mb-4"
            gallery
            greyDots
            arrows={false}
            responsiveSettings={[
              { breakpoint: 10000, settings: { slidesToShow: 3 } },
              { breakpoint: 1024, settings: { slidesToShow: 3 } },
              { breakpoint: 992, settings: { slidesToShow: 3 } },
              { breakpoint: 768, settings: { slidesToShow: 2 } },
              { breakpoint: 450, settings: { slidesToScroll: 1 } }
            ]}
          >
            <Slide>
              <Card
                className="features-section__card mb-lg-0 px-lg-4"
                image={
                  <SvgInline css={useStrokeOverride} name="party-popper" />
                }
                title={<h5>{PageData.content.features.noAnnualFeeTitle}</h5>}
                content={PageData.content.features.noAnnualFeeDesc}
              />
            </Slide>
            <Slide>
              <Card
                className="features-section__card mb-lg-0 px-lg-4"
                image={
                  <SvgInline css={useStrokeOverride} name="money-bundle" />
                }
                title={
                  <h5>
                    {PageData.content.features.streamlinedRatesTitle}
                    <sup>#</sup>
                  </h5>
                }
                content={
                  <div>
                    {PageData.content.features.streamlinedRatesDesc1}
                    <sup>#</sup>
                    {PageData.content.features.streamlinedRatesDesc2}
                  </div>
                }
              />
            </Slide>
            <Slide>
              <Card
                className="features-section__card mb-lg-0 px-lg-4"
                image={<SvgInline css={useStrokeOverride} name="icon-globe" />}
                title={<h5>{PageData.content.features.spendWheneverTitle}</h5>}
                content={PageData.content.features.spendWheneverDesc}
              />
            </Slide>
          </Slider>
        </Section>

        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFees
            id="rates-fees"
            title="Rates, Fees & Conditions Of Use"
            className="rates-list-section"
            ratesListProps={{ data: PageData.ratesList }}
            accordionProps={{
              items: [
                {
                  id: 'account-fees',
                  title: 'Account fees',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Annual fee</strong>
                        </p>
                        <p className="w-100">$0</p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Additional cardholder fee</strong>
                        </p>
                        <p className="w-100">$0</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Paper statement fee</strong>
                        </p>
                        <p className="w-100">
                          $0.50 per statement (avoid this by opting out of paper
                          statements via the Latitude Service Centre)
                        </p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'transaction-fees',
                  title: 'Transaction fees',
                  content: (
                    <StyledRatesBox className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Cash advance fee</strong>
                        </p>
                        <p className="w-100">
                          <ul>
                            <li>$2 - over the counter cash advance</li>
                            <li>$1 - NZ ATM withdrawal</li>
                            <li>$1.50 - International ATM Withdrawal</li>
                            <li>Other ATM fees may apply</li>
                          </ul>
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>International Transaction Fee</strong>
                        </p>
                        <p className="w-100">
                          1.95% of the purchase or cash advance
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Late fee</strong>
                        </p>
                        <p className="w-100">$15</p>
                      </div>
                    </StyledRatesBox>
                  )
                },
                {
                  id: 'interchange-fees',
                  title: 'Interchange fees',
                  content: (
                    <div className="accordion-custom">
                      <div css="padding: 20px 30px 0 30px">
                        Interchange fees are paid by a business that processes
                        card transactions on behalf of a merchant (acquirer) to
                        the card issuer (Latitude Financial Services Limited in
                        the case of Gem Essential). The interchange fees payable
                        for all Gem Essential domestic Credit Card transactions
                        are set out below.
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry program: Charities</strong>
                        </p>
                        <p className="w-100">0.39%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants Card Present (CP) and Card Not
                            Present (CNP): Rate 1{' '}
                          </strong>
                        </p>
                        <p className="w-100">0.50%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 2
                          </strong>
                        </p>
                        <p className="w-100">0.55%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 3
                          </strong>
                        </p>
                        <p className="w-100">0.60%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 4
                          </strong>
                        </p>
                        <p className="w-100">0.70%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 5
                          </strong>
                        </p>
                        <p className="w-100">0.80%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 6
                          </strong>
                        </p>
                        <p className="w-100">0.98%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry Program: Insurance</strong>
                        </p>
                        <p className="w-100">$1</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry Program: Recurring Payment</strong>
                        </p>
                        <p className="w-100">0.70%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Industry Program: Government, Utilities and Telecom
                          </strong>
                        </p>
                        <p className="w-100">$0.70</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Contactless</strong>
                        </p>
                        <p className="w-100">0.65%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Electronic</strong>
                        </p>
                        <p className="w-100">0.65%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Standard</strong>
                        </p>
                        <p className="w-100">1.25%</p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'conditions',
                  title: 'Gem Essential Conditions of Use',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            These are the Gem Essential Conditions of Use, which
                            govern your Gem Essential credit card and form part
                            of your credit contract.
                          </strong>
                        </p>
                        <p className="w-100">
                          <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/GemEssential/cou.pdf">
                            <img
                              width="100px"
                              src="/media/gem-essential-cou.png"
                              alt="Gem Essential Condition of Use"
                            />
                            <br />
                            Download Conditions of Use
                          </a>
                        </p>
                      </div>
                    </div>
                  )
                }
              ]
            }}
          />
        )}

        <DigitalWallets id="digital-wallets" />

        <CalloutHelp />

        <Box id="faq">
          <div data-contentful={state?.faqData?.[0]?.contentfulID}>
            <Box.Section>
              <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
                {state?.faqData?.[0]?.title || 'Frequently asked questions'}
              </Heading4>
              <AccordionSidebar
                data={
                  getAccordionSidebarData(state?.faqData?.[0]?.data) ||
                  faqsCredit.gemEssential
                }
              />
            </Box.Section>
          </div>
        </Box>

        <ImportantInformation
          id="important-information"
          data={require('../../data/json/disclaimer/gem-essential.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-10 offset-lg-1"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

const GemEssentialVisaPageWithSticky = withStickyState(GemEssentialVisaPage);
export default GemEssentialVisaPageWithSticky;
