import React from 'react';
import PropTypes from 'prop-types';

const Slide = props => {
  const { className, ...otherProps } = props;
  let newClassName = 'lfs-slide ';
  newClassName += className || '';

  return (
    <div className={newClassName} {...otherProps}>
      {props.children}
    </div>
  );
};

Slide.propTyes = { className: PropTypes.string };

export default Slide;
